import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './supply-to-demand-filter.reducer';

export const SupplyToDemandFilterDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const supplyToDemandFilterEntity = useAppSelector(state => state.supplyToDemandFilter.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="supplyToDemandFilterDetailsHeading">Supply To Demand Filter</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.id}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.name}</dd>
          <dt>
            <span id="filterExpression">Filter Expression</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.filterExpression}</dd>
          <dt>
            <span id="margin">Margin</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.margin}</dd>
          <dt>
            <span id="vastSetting">Vast Setting</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.vastSetting}</dd>
          <dt>
            <span id="qps">Qps</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.qps}</dd>
          <dt>
            <span id="isEnabled">Is Enabled</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.isEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="isSellerSchainRequired">Is Seller Schain Required</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.isSellerSchainRequired ? 'true' : 'false'}</dd>
          <dt>
            <span id="isSellerSchainOverride">Is Seller Schain Override</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.isSellerSchainOverride ? 'true' : 'false'}</dd>
          <dt>
            <span id="schain">Schain</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.schain}</dd>
          <dt>
            <span id="comment">Comment</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.comment}</dd>
          <dt>
            <span id="bcat">Bcat</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.bcat}</dd>
          <dt>
            <span id="badv">Badv</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.badv}</dd>
          <dt>
            <span id="isArchived">Is Archived</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.isArchived ? 'true' : 'false'}</dd>
          <dt>
            <span id="gppPrefix">Gpp Prefix</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.gppPrefix}</dd>
          <dt>
            <span id="skadNetworkIds">Skad Network Ids</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.skadNetworkIds}</dd>
          <dt>
            <span id="storedImpStr">Stored Imp Str</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.storedImpStr}</dd>
          <dt>
            <span id="storedRequestStr">Stored Request Str</span>
          </dt>
          <dd>{supplyToDemandFilterEntity.storedRequestStr}</dd>
          <dt>Demand</dt>
          <dd>{supplyToDemandFilterEntity.demand ? supplyToDemandFilterEntity.demand.name : ''}</dd>
          <dt>Supply</dt>
          <dd>
            {supplyToDemandFilterEntity.supplies
              ? supplyToDemandFilterEntity.supplies.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.name}</a>
                    {supplyToDemandFilterEntity.supplies && i === supplyToDemandFilterEntity.supplies.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/supply-to-demand-filter" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/supply-to-demand-filter/${supplyToDemandFilterEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default SupplyToDemandFilterDetail;
