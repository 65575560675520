import { IUser } from 'app/shared/model/user.model';
import { ISupplyPartner } from 'app/shared/model/supply-partner.model';
import { ISupplyToDemandFilter } from 'app/shared/model/supply-to-demand-filter.model';
import { Datacenter } from 'app/shared/model/enumerations/datacenter.model';

export interface ISupply {
  id?: number;
  name?: string | null;
  isEnabled?: boolean | null;
  supplyDomain?: string | null;
  isThirdPartySupply?: boolean | null;
  type?: string | null;
  requestProcessingTime?: number | null;
  defaultSupplyTmax?: number | null;
  blockedCreativeAttributes?: string | null;
  blockedCategories?: string | null;
  blockedBannerAdTypes?: string | null;
  datacenter?: keyof typeof Datacenter | null;
  supplyDescription?: string | null;
  isArchived?: boolean | null;
  isMaxMindEnabled?: boolean | null;
  isAuctionPriceMacroEnabled?: boolean | null;
  user?: IUser | null;
  supplyPartner?: ISupplyPartner | null;
  stodfs?: ISupplyToDemandFilter[] | null;
}

export const defaultValue: Readonly<ISupply> = {
  isEnabled: false,
  isThirdPartySupply: false,
  isArchived: false,
  isMaxMindEnabled: false,
  isAuctionPriceMacroEnabled: false,
};
