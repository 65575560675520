import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SupplyPartner from './supply-partner';
import DemandPartner from './demand-partner';
import Supply from './supply';
import Demand from './demand';
import SupplyToDemandFilter from './supply-to-demand-filter';
import ContentTaxonomy from './content-taxonomy';
import TimeZone from './time-zone';
import UiReportFilter from './ui-report-filter';
import UserAccount from './user-account';
import ScheduleReport from './schedule-report';
import DemandAppApprovalStatus from './demand-app-approval-status';
import Notification from './notification';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="supply-partner/*" element={<SupplyPartner />} />
        <Route path="demand-partner/*" element={<DemandPartner />} />
        <Route path="supply/*" element={<Supply />} />
        <Route path="demand/*" element={<Demand />} />
        <Route path="supply-to-demand-filter/*" element={<SupplyToDemandFilter />} />
        <Route path="content-taxonomy/*" element={<ContentTaxonomy />} />
        <Route path="time-zone/*" element={<TimeZone />} />
        <Route path="ui-report-filter/*" element={<UiReportFilter />} />
        <Route path="user-account/*" element={<UserAccount />} />
        <Route path="schedule-report/*" element={<ScheduleReport />} />
        <Route path="demand-app-approval-status/*" element={<DemandAppApprovalStatus />} />
        <Route path="notification/*" element={<Notification />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
