import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UiReportFilter from './ui-report-filter';
import UiReportFilterDetail from './ui-report-filter-detail';
import UiReportFilterUpdate from './ui-report-filter-update';
import UiReportFilterDeleteDialog from './ui-report-filter-delete-dialog';

const UiReportFilterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UiReportFilter />} />
    <Route path="new" element={<UiReportFilterUpdate />} />
    <Route path=":id">
      <Route index element={<UiReportFilterDetail />} />
      <Route path="edit" element={<UiReportFilterUpdate />} />
      <Route path="delete" element={<UiReportFilterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UiReportFilterRoutes;
