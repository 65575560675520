import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ScheduleReport from './schedule-report';
import ScheduleReportDetail from './schedule-report-detail';
import ScheduleReportUpdate from './schedule-report-update';
import ScheduleReportDeleteDialog from './schedule-report-delete-dialog';

const ScheduleReportRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ScheduleReport />} />
    <Route path="new" element={<ScheduleReportUpdate />} />
    <Route path=":id">
      <Route index element={<ScheduleReportDetail />} />
      <Route path="edit" element={<ScheduleReportUpdate />} />
      <Route path="delete" element={<ScheduleReportDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ScheduleReportRoutes;
