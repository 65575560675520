import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TimeZone from './time-zone';
import TimeZoneDetail from './time-zone-detail';
import TimeZoneUpdate from './time-zone-update';
import TimeZoneDeleteDialog from './time-zone-delete-dialog';

const TimeZoneRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TimeZone />} />
    <Route path="new" element={<TimeZoneUpdate />} />
    <Route path=":id">
      <Route index element={<TimeZoneDetail />} />
      <Route path="edit" element={<TimeZoneUpdate />} />
      <Route path="delete" element={<TimeZoneDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TimeZoneRoutes;
