import { IDemand } from 'app/shared/model/demand.model';
import { ISupply } from 'app/shared/model/supply.model';

export interface ISupplyToDemandFilter {
  id?: number;
  name?: string | null;
  filterExpression?: string | null;
  margin?: number | null;
  vastSetting?: string | null;
  qps?: number | null;
  isEnabled?: boolean | null;
  isSellerSchainRequired?: boolean | null;
  isSellerSchainOverride?: boolean | null;
  schain?: string | null;
  comment?: string | null;
  bcat?: string | null;
  badv?: string | null;
  isArchived?: boolean | null;
  gppPrefix?: string | null;
  skadNetworkIds?: string | null;
  storedImpStr?: string | null;
  storedRequestStr?: string | null;
  demand?: IDemand | null;
  supplies?: ISupply[] | null;
}

export const defaultValue: Readonly<ISupplyToDemandFilter> = {
  isEnabled: false,
  isSellerSchainRequired: false,
  isSellerSchainOverride: false,
  isArchived: false,
};
