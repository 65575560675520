import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DemandPartner from './demand-partner';
import DemandPartnerDetail from './demand-partner-detail';
import DemandPartnerUpdate from './demand-partner-update';
import DemandPartnerDeleteDialog from './demand-partner-delete-dialog';

const DemandPartnerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DemandPartner />} />
    <Route path="new" element={<DemandPartnerUpdate />} />
    <Route path=":id">
      <Route index element={<DemandPartnerDetail />} />
      <Route path="edit" element={<DemandPartnerUpdate />} />
      <Route path="delete" element={<DemandPartnerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DemandPartnerRoutes;
